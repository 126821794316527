<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Name"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Ref No"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['ref_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Postal Code"
              label-for="v-postal-code"
            >
              <b-form-input
                id="v-postal-code"
                v-model="filters['postal_code']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Default Warehouse"
              label-for="v-ref-no"
            >
              <v-select
                id="v-ref-no"
                v-model="filters['default_warehouse_id']"
                :options="warehouseOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Warehouse"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Active Status"
              label-for="active_status"
            >
              <v-select
                v-model="filters['is_active']"
                :options="statusOptions"
                :reduce="option => option.key"
                label="name"
                placeholder="Select Status"
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Zone Status"
              label-for="active_status"
            >
              <v-select
                v-model="filters['zone_id']"
                :options="zoneStatusOptions"
                :reduce="option => option.key"
                label="name"
                placeholder="Select Status"
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="State"
              label-for="state"
            >
              <v-select
                v-model="filters['state_id']"
                :options="stateOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select State"
              >
                <template
                  v-slot:option="option"
                  class="text-truncate"
                >
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <div
              class="m-2"
            >
              <!-- input -->
              <b-row>
                <b-col
                  md="7"
                  class="d-flex align-items-center justify-content-start mb-1 mt-1 mb-md-0"
                >
                  <b-button
                    v-can="'city-create'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="custom-button-color"
                    @click="onClickCreateCity"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add New
                  </b-button>
                  <b-col
                    md="4"
                  >
                    <b-button
                      v-can="'city-create'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      to="cities/city-create"
                    >
                      <feather-icon icon="PlusIcon" />
                      Excel Upload
                    </b-button>
                  </b-col>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                >
                  <div>
                    <b-tooltip
                      target="city-activate"
                      triggers="hover"
                      variant="success"
                    >
                      Activate Cities
                    </b-tooltip>
                    <div id="city-activate">
                      <b-button
                        v-can="'city-edit'"
                        :disabled="selectedCities.length === 0"
                        variant="success"
                        class="mt-2 mr-1"
                        :style="selectedCities.length === 0 ? 'pointer-events:none;':''"
                        @click="updateCityStatuses(selectedCities, true)"
                      >
                        <feather-icon icon="ChevronsUpIcon" />
                      </b-button>
                    </div>
                  </div>
                  <div>
                    <b-tooltip
                      target="city-deactivate"
                      triggers="hover"
                      variant="danger"
                    >
                      Deactivate Cities
                    </b-tooltip>
                    <div id="city-deactivate">
                      <b-button
                        v-can="'city-edit'"
                        :disabled="selectedCities.length === 0"
                        variant="danger"
                        class="mt-2 mr-1"
                        :style="selectedCities.length === 0 ? 'pointer-events:none;':''"
                        @click="updateCityStatuses(selectedCities, false)"
                      >
                        <feather-icon icon="ChevronsDownIcon" />
                      </b-button>
                    </div>
                  </div>
                  <div>
                    <b-tooltip
                      target="assign-default-warehouse"
                      triggers="hover"
                      variant="primary"
                    >
                      Assign Default Warehouse
                    </b-tooltip>
                    <div id="assign-default-warehouse">
                      <b-button
                        v-can="'city-edit'"
                        :disabled="selectedCities.length === 0"
                        variant="primary"
                        class="mt-2 mr-1"
                        :style="selectedCities.length === 0 ? 'pointer-events:none;':''"
                        @click="onClickBulkAssign()"
                      >
                        <feather-icon icon="TruckIcon" />
                      </b-button>
                    </div>
                  </div>
                  <ReportTableButtons
                    class="mt-2"
                    :items="rows"
                    :json_fieldz="json_fields"
                    :name="`Cities - Page ${page}.xls`"
                    :bulkname="`Cities.xls`"
                    :fetch="fetchCityListNoPagination"
                    @refresh="onClickRefresh"
                  />
                </b-col>
              </b-row>
            </div>
            <!-- table -->
            <b-table
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #head(id)>
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template #cell(id)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedCities"
                        :value="data.item.id"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.item.name }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ data.item.ref_no }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(postal_code)="data">
                {{ data.value || '---' }}
              </template>

              <template #cell(zone.name)="data">
                {{ data.value || '---' }}
              </template>

              <template #cell(is_active)="data">
                <div>
                  <b-badge :variant="`light-${data.value?'success':'danger'}`">
                    {{ data.value ? 'Active' : 'Inactive' }}
                  </b-badge>
                </div>
              </template>

              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="12"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'city-edit'"
                    @click="updateCityStatuses([data.item.id], !data.item.is_active)"
                  >
                    <feather-icon :icon="data.item.is_active ?'XIcon':'CheckIcon'" />
                    {{ data.item.is_active ? 'Deactivate City':'Activate City' }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'city-edit'"
                    @click="onClickEditCity(data.item.id)"
                  >
                    <feather-icon icon="ToolIcon" />
                    Manage City
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  cols="12"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-1 width-100 mr-2"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  cols="12"
                  md="9"
                >
                  <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-overlay>

        </div>
      </b-card>
    </div>
    <set-city ref="set_city" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BFormCheckbox,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import SetCity from '@/components/demarcation/SetCityModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BFormCheckbox,
    BTooltip,
    SetCity,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      filters: {},
      warehouseOptions: [],
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      // End BS Table
      pageLength: 20,
      dir: false,
      selectedCities: [],
      statusOptions: [
        {
          name: 'Active',
          key: '1',
        },
        {
          name: 'Inactive',
          key: '0',
        },
      ],
      zoneStatusOptions: [
        {
          name: 'Assigned to Zones',
          key: true,
        },
        {
          name: 'Not Assigned to Zones',
          key: 'null',
        },
      ],
      stateOptions: [],
      fields: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'ref_no',
          label: 'Ref No',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'name',
          label: 'City',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'postal_code',
          label: 'Postal Code',
          sortable: true,
        },
        {
          key: 'state.name',
          label: 'State',
          sortable: true,
        },
        {
          key: 'zone.name',
          label: 'Zone',
          sortable: true,
        },
        {
          key: 'country.name',
          label: 'Country',
          sortable: true,
        },
        {
          key: 'default_warehouse.name',
          label: 'Default Warehouse',
          sortable: true,
        },
        {
          key: 'is_active',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      json_fields: {
        City: {
          field: 'name',
          callback: value => `"${value}"`,
        },
        'Ref No.': {
          field: 'ref_no',
          callback: value => `"${value}"`,
        },
        'Postal Code': {
          field: 'postal_code',
          callback: value => `"${value}"`,
        },
        State: {
          field: 'state.name',
          callback: value => `"${value}"`,
        },
        Zone: {
          field: 'zone.name',
          callback: value => `"${value}"`,
        },
        Country: 'country.name',
        'Default Warehouse': {
          field: 'default_warehouse.name',
          callback: value => `"${value}"`,
        },
        Status: {
          field: 'is_active',
          callback: value => (value ? 'Active' : 'Inactive'),
        },
      },
      rows: [],
      searchTerm: '',
      selectAllCheckbox: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchCityList()
      },
    },
    deep: true,
    page() {
      this.fetchCityList()
    },
    perPage() {
      this.fetchCityList()
    },
  },
  mounted() {
    this.fetchCityList()
    this.getWarehouseList()
    this.getStateList()
  },
  methods: {
    async fetchCityList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await ResourceRepository.getCityList(this.page, this.filterQuery, this.perPage, this.sort, true))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchCityListNoPagination() {
      try {
        const { data } = (await ResourceRepository.getCityListNoPagination(this.filterQuery, this.sort, true))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getWarehouseList() {
      try {
        this.warehouseOptions = localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : (await ResourceRepository.getWarehouseListForDropDown()).data.data
        if (!localStorage.getItem('warehouse_list')) localStorage.setItem('warehouse_list', JSON.stringify(this.warehouseOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async updateCityStatuses(ids, activate) {
      return this.$swal({
        title: 'Are you sure',
        icon: 'info',
        text: `Are you sure you want to ${activate ? 'activate' : 'deactivate'} this city?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.updateStatuses(ids, activate)
        }
      })
    },
    async updateStatuses(ids, activate) {
      try {
        const payload = {
          city_id: ids,
        }
        let res
        if (activate) {
          res = await ResourceRepository.activateCities(payload)
        } else {
          res = await ResourceRepository.deactivateCities(payload)
        }
        if (res.status === 200) {
          this.showSuccessMessage(`Cities ${activate ? 'Activated' : 'Deactivated'} Successfully`)
          localStorage.removeItem('city_list')
          this.onClickDeselctAll()
          this.onClickRefresh()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickCreateCity() {
      this.$refs.set_city.openModal(null, 'add')
    },
    onClickEditCity(id) {
      this.$refs.set_city.openModal(id, 'edit')
    },
    onClickBulkAssign() {
      this.$refs.set_city.openModal(null, 'setWarehouse', this.selectedCities, 'City')
    },
    onClickSelectAll() {
      this.selectedCities = this.rows.map(n => n.id)
    },
    onClickDeselctAll() {
      this.selectedCities = []
    },
    changePage(value) {
      this.perPage = value
    },
    handleChangePage(page) {
      this.page = page
      this.fetchCityList()
    },
    applyFilters() {
      this.fetchCityList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchCityList()
      })
    },
    onClickRefresh() {
      this.fetchCityList()
    },
    async getStateList() {
      try {
        this.stateOptions = localStorage.getItem('state_list') ? JSON.parse(localStorage.getItem('state_list')) : (await ResourceRepository.getStateListDropdown()).data.data
        if (!localStorage.getItem('state_list')) localStorage.setItem('state_list', JSON.stringify(this.stateOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedCities = this.rows.map(n => n.id)
      } else {
        this.selectedCities = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
