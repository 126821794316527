<template>
  <div>
    <b-modal
      id="modal-city-add"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      :title="modalTitle"
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createCityForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col
                v-if="modalPurpose!='setWarehouse'"
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="City Name"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    placeholder="Galle"
                    type="text"
                    autocomplete="nope"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalPurpose!='setWarehouse'"
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="Postal Code/Zip Code"
                  label-cols-md="4"
                  label-for="h-pCode"
                >

                  <b-form-input
                    id="h-pCode"
                    v-model="form.postal_code"
                    placeholder="Enter Suitable Postal Code"
                    type="text"
                    autocomplete="nope"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalPurpose!='setWarehouse'"
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="State"
                  label-cols-md="4"
                  label-for="h-state"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-select
                      v-model="form.state_id"
                      :options="stateOptions"
                      :reduce="option => option.id"
                      label="name"
                      placeholder="Select State"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalPurpose!='setWarehouse'"
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="Country"
                  label-cols-md="4"
                  label-for="h-country"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-select
                      v-model="form.country_id"
                      :options="countryOptions"
                      :reduce="option => option.id"
                      label="name"
                      placeholder="Select Country"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="showRadio"
                cols="12"
              >
                <b-form-group
                  label="Default Warehouse Type"
                  label-cols-md="4"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="default_warehouse_selected"
                      class="custom-control-primary"
                      name="warehouseTypeRadio"
                      value="Agent"
                      @change="setWarehouseType"
                    >
                      Agent
                    </b-form-radio>
                    <b-form-radio
                      v-model="default_warehouse_selected"
                      class="custom-control-primary"
                      name="warehouseTypeRadio"
                      value="Branch"
                      @change="setWarehouseType"
                    >
                      Branch
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Default Warehouse"
                  label-cols-md="4"
                  label-for="h-ware"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-select
                      v-model="form.default_warehouse_id"
                      :options="warehouseOptions"
                      :placeholder="warehousePlaceholder"
                      :reduce="option => option.id"
                      label="name"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                offset-md="4"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1 custom-button-color"
                    type="submit"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="clearOnClick"
                  >
                    Clear
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormRadio,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')

function initialState() {
  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    fields: [],
    form: {},
    default_warehouse_selected: 'Agent',
    warehousePlaceholder: 'Select Agent',
    warehouseOptions: [],
    countryOptions: [],
    stateOptions: [],
    branchOptions: [],
    agencyOptions: [],
    selectedCities: null,
    showRadio: true,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormRadio,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      // eslint-disable-next-line no-nested-ternary
      return this.modalPurpose === 'add' ? 'ADD NEW CITY' : this.modalPurpose === 'edit' ? 'UPDATE CITY' : 'SET DEFAULT WAREHOUSE'
    },
  },
  methods: {
    async openModal(id, purpose, selectedCities, updateOrigin) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      this.selectedCities = selectedCities
      if (updateOrigin === 'Agent' || updateOrigin === 'Branch') {
        this.default_warehouse_selected = updateOrigin
        this.showRadio = false
      }
      await this.readDataforCountry()
      await this.readDataforState()
      await this.readDataforAgency()
      await this.readDataforBranch()
      this.setWarehouseType()
      if (this.modalPurpose === 'edit') {
        await this.fetchCityDetails(this.id)
      }
      this.loading = false
    },
    async readDataforCountry() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforState() {
      try {
        const { data } = (await ResourceRepository.getStateListDropdown()).data
        this.stateOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforBranch() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        this.branchOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforAgency() {
      try {
        this.agencyOptions = localStorage.getItem('agent_list') ? JSON.parse(localStorage.getItem('agent_list')) : (await ResourceRepository.getAgencyListDropdown()).data.data
        if (!localStorage.getItem('agent_list')) localStorage.setItem('agent_list', JSON.stringify(this.agencyOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    setWarehouseType() {
      if (this.default_warehouse_selected === 'Agent') {
        this.warehouseOptions = this.agencyOptions
        this.warehousePlaceholder = 'Select Agent'
      } else if (this.default_warehouse_selected === 'Branch') {
        this.warehouseOptions = this.branchOptions
        this.warehousePlaceholder = 'Select Branch'
      }
      this.form.default_warehouse_id = ''
    },
    async fetchCityDetails(id) {
      try {
        const { data } = (await ResourceRepository.getCityById(id)).data
        this.form = data
        if (!this.form.default_warehouse.is_agent) {
          this.default_warehouse_selected = 'Branch'
          this.warehouseOptions = this.branchOptions
          this.warehousePlaceholder = 'Select Branch'
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleFormSubmit() {
      try {
        this.loading = true
        const data = {
          name: this.form.name,
          state_id: this.form.state_id,
          postal_code: this.form.postal_code,
          country_id: this.form.country_id,
          default_warehouse_id: this.form.default_warehouse_id,
        }
        let res
        if (this.modalPurpose === 'add') {
          res = await ResourceRepository.createCity(data)
        } else if (this.modalPurpose === 'edit') {
          res = await ResourceRepository.updateCity(this.form.id, data)
        } else {
          this.handleBulkAssignWarehouse()
        }
        if (res.status === 200 || res.status === 201) {
          this.clearOnClick()
          this.open = false
          this.$parent.onClickRefresh()
          localStorage.removeItem('city_list')
          this.showSuccessMessage(`City ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
        } else {
          this.showErrorMessage(`Cannot ${this.modalPurpose === 'add' ? 'Add' : 'Update'} City`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleBulkAssignWarehouse() {
      try {
        this.loading = true
        const data = {
          city_id: this.selectedCities,
          default_warehouse_id: this.form.default_warehouse_id,
        }
        const res = await ResourceRepository.assignDefaultWarehouse(data)
        if (res.status === 200) {
          this.clearOnClick()
          this.open = false
          this.$parent.onClickRefresh()
          this.$parent.selectedCities = []
          this.showSuccessMessage('Default Warehouse Updated Successfully')
        } else {
          this.showErrorMessage('Cannot Set Default Warehouse')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearOnClick() {
      this.default_warehouse_selected = 'Agent'
      this.form = {}
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
